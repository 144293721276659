<template>
    <div class="select-wrap" :class="{spin_item: !reportsLoaded || saving}">
        <div class="radio-area">
            <input :id="`outbid-${id}`" type="checkbox" v-model.boolean="isReseller" :name="`outbid-${id}`">
            <label :for="`outbid-${id}`" class="radio-btn">Перекуп</label>
        </div>

        <div class="radio-area" v-if="!withoutAdditionalOptions">
            <input :id="`beaten-${id}`" type="checkbox" v-model.boolean="damaged"  :name="`beaten-${id}`">
            <label :for="`beaten-${id}`" class="radio-btn">Битая</label>
        </div>
        <label v-if="!withoutAdditionalOptions" class="label">Реальная цена авто</label>
        <div class="radio-area" v-if="!withoutAdditionalOptions">
            <template v-for="(price, index) of realPriceRange">
                <input
                        :id="`real-price-${index}-${id}`"
                        type="radio"
                        :value="price"
                        :name="`real-price-${id}`"
                        v-model="realPrice"
                >
                <label :for="`real-price-${index}-${id}`" class="radio-btn">{{price.toLocaleString(langCode, {style: 'decimal'})}}$</label>
            </template>
        </div>
        <label class="label" v-if="!withoutAdditionalOptions">За сколько бы взял</label>
        <div class="radio-area" v-if="!withoutAdditionalOptions">

            <template v-for="(price, index) of buyPriceRange">
                <input
                        :id="`price-${index}-${id}`"
                        type="radio" :value="price"
                        :name="`price-${id}`"
                        v-model="buyPrice"
                >
                <label :for="`price-${index}-${id}`" class="radio-btn">{{price.toLocaleString(langCode, {style: 'decimal'})}}$</label>
            </template>
        </div>

        <div class="radio-area" v-if="!withoutAdditionalOptions">
            <input :id="`no-demand-${id}`" type="checkbox" v-model.boolean="noDemand" :name="`no-demand-${id}`">
            <label :for="`no-demand-${id}`" class="radio-btn">нет спроса</label>
        </div>
        <div class="radio-area" v-if="!withoutAdditionalOptions">
            <input :id="`is-american-${id}`" type="checkbox" v-model.boolean="isAmerican" :name="`is-american-${id}`" v-on:change.prevent>
            <label :for="`is-american-${id}`" class="radio-btn">Американка</label>
        </div>
    </div>
</template>
<script >
	import {mapActions, mapGetters} from 'vuex';

	export default {
    name: 'EvaluationForm',
    props: ['id'],
    data(){
      return {
        saving: false
      }
    },
    computed: {
      ...mapGetters({
        _request: 'estimator/request',
        _report: 'estimator/report',
        _reports: 'estimator/reports',
        _info: 'estimator/info',
        reportsLoaded: 'estimator/reportsLoaded'
      }),
      withoutAdditionalOptions() {
        return this.$route.meta.withoutAdditionalOptions;
      },
      request(){
        return this._request(this.id);
      },
      report(){
        return this._reports[this.id];
      },
      info(){
        return this._info(this.id);
      },
      buyPriceRange() {
        return this.info.price.buy;
      },
      realPriceRange() {
        return this.info.price.real;
      },
      realPrice: {
        get() {
          return this.report.realPrice;
        },
        set(val) {
          this.saving = true;
          return this
            .saveField({id: Number(this.id), data: {key: 'realPrice', val}})
            .finally(() => this.saving = false)
            ;
        }
      },
      buyPrice: {
        get() {
          return this.report.buyPrice;
        },
        set(val) {
          this.saving = true;
          return this
            .saveField({id: Number(this.id), data: {key: 'buyPrice', val}})
            .finally(() => this.saving = false)
            ;
        }
      },
      isReseller: {
        get(){
          return this.report.isReseller;
        },
        set(val){
          this.saving = true;
          return this
            .saveField({id: Number(this.id), data: {key: 'isReseller', val}})
            .finally(() => this.saving = false)
            ;
        }
      },
      damaged: {
        get(){
          return this.report.damaged;
        },
        set(val){
          this.saving = true;
          return this
            .saveField({id: Number(this.id), data: {key: 'damaged', val}})
            .finally(() => this.saving = false)
            ;
        }
      },
      noDemand: {
        get(){
          return this.report.noDemand;
        },
        set(val){
          this.saving = true;
          return this
            .saveField({id: Number(this.id), data: {key: 'noDemand', val}})
            .finally(() => this.saving = false)
            ;
        }
      },
      isAmerican: {
      	get() {
          return this.report.isAmerican;
        },
        set(val){
          this.saving = true;
          return this
              .saveField({id: Number(this.id), data: {key: 'isAmerican', val}})
              .finally(() => this.saving = false)
              ;
        }
      }

    },
    methods: {
      ...mapActions({
        saveField: 'estimator/saveField'
      })
    }
  }
</script>
