<template>
  <div class="boxed" v-if="pages > 1 || (count >= 10 && page==1)">
    <nav class="unstyle pager">
        <span class="page-item prev" @click="goto(page - 1)">
            <a class="page-link js-prev" :class="{disabled: page === 1}" tabindex="-1" href="javascript:void('pagination[back]')">←<span
                class="mhide"> Назад</span></a>
        </span>

      <span class="page-item mhide" v-if="page >= 2" @click="goto(1)">
            <a class="page-link" href="javascript:void('pagination[click]')">1</a>
        </span>
      <span class="page-item mhide text-c" v-if="page >= 4">...</span>
      <span class="page-item mhide" v-if="page >= 3" @click="goto(page - 1)">
            <a class="page-link" href="javascript:void('pagination[click]')">{{page - 1}}</a>
        </span>

      <span class="page-item mhide" @click="goto(page)">
            <a class="page-link active" href="javascript:void('pagination[click]')">{{page}}</a>
        </span>

      <span class="page-item mhide" v-if="page <= pages - 2" @click="goto(page + 1)">
            <a class="page-link" href="javascript:void('pagination[click]')">{{page + 1}}</a>
        </span>
      <span class="page-item mhide" v-if="page <= pages - 3" @click="goto(page + 2)">
            <a class="page-link" href="javascript:void('pagination[click]')">{{page + 2}}</a>
        </span>
      <span class="page-item mhide text-c" v-if="page <= pages - 4">...</span>
      <span class="page-item mhide" v-if="page <= pages - 1" @click="goto(pages)">
            <a class="page-link" href="javascript:void('pagination[click]')">{{pages}}</a>
        </span>

      <span class="page-item dhide text-c">{{page}} / {{pages.toLocaleString()}}</span>

      <span class="page-item next text-r" @click="goto(page + 1)">
            <a class="page-link js-next" :class="{disabled: page === pages}" href="javascript:void('pagination[forward]')">Вперед →</a>
        </span>
    </nav>
  </div>
</template>

<script>
import scrollToTop from '../../helpers/cabinetScroll';

export default {
  name: 'Pagination',
  data: function () {
    return {
      isTooltipOpened: false
    };
  },
  props: ['page', 'pages', 'count'],
  watch: {},
  mounted() {
  },
  methods: {
    toggleTooltip() {
      this.isTooltipOpened = !this.isTooltipOpened;
    },
    goto(page) {
      if (page > 0 && page <= this.pages) {
        this.scrollToTop(0);
        this.$emit('onChangePage', page);
      }
    },
    scrollToTop
  },
  computed: {},
  i18n: {
    messages: {
      uk: {
        'Показывать по': 'Показувати по',
        объявлений: 'оголошень'
      },
      ru: {
        'Показывать по': 'Показывать по',
        объявлений: 'объявлений'
      }
    }
  }
};

</script>
