<template>
    <div class="list-item">
        <div class="photo-item loaded">
            <a :href="link" style="position: absolute; width: 100%; height: 100%; z-index: 1111;">
                <img :src="imgLink" :href="link" :alt="title" :title="title" class="loadPhoto loaded">
            </a>
        </div>
        <span class="checked_ad" style="display: none;"><svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 3H2L5 0H11L11.75 0.75L8 4.5L6 2.5L4.5 4L8 7.5L13.25 2.25L14 3H15.5C15.7761 3 16 3.22386 16 3.5C16 3.77614 15.7761 4 15.5 4H15V10C15 10.5523 14.5523 11 14 11H13C12.4477 11 12 10.5523 12 10V9H4V10C4 10.5523 3.55228 11 3 11H2C1.44772 11 1 10.5523 1 10V4H0.5C0.223858 4 0 3.77614 0 3.5C0 3.22386 0.223858 3 0.5 3Z" fill="white"></path></svg> Перевірений VIN-код</span>
        <a :href="link" target="_blank" class="size18 link">{{title}}</a>
        <div class="price-ticket">
            <span class="bold green size22">{{info.price.value.toLocaleString(langCode, {style: 'decimal'})}} $</span><span class="point">•</span><span class="i-block">{{info.price.valueUah.toLocaleString(langCode, {style: 'decimal'})}} грн </span>
        </div>
        <ul class="unstyle boxed">
            <li class="item-char">
                <svg data-tag="svg" class="svg icon-mileage">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg_mileage"></use>
                </svg>
                {{info.race}} тыс. км
            </li>
            <li class="item-char view-location">
                <svg data-tag="svg" class="svg icon-location">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg_location"></use>
                </svg>
                {{info.city.name}}
            </li>
            <li class="item-char">
                <svg data-tag="svg" class="svg icon-fuel">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg_fuel"></use>
                </svg>
                {{info.fuel.name}}
            </li>
            <li class="item-char" v-if="info.gearbox.name">
                <svg data-tag="svg" class="svg icon-transmission">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg_transmission"></use>
                </svg>
                {{info.gearbox.name}}
            </li>
        </ul>
        <div class="footer-ticket">
            <span v-if="createdAt">
                <svg data-tag="svg" :class="{'svg icon-time-green': __addDateDiff, 'svg icon-time-grey': !__addDateDiff}">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg_time"></use>
                </svg>
                <span :class="{green: __addDateDiff, grey: !__addDateDiff}"> {{__addDateText}}</span>
            </span>
            <div style="position: relative; margin: 10px 0; padding: 0 10px;">
                <span class="label-vin" v-if="info.vin">
                    <svg fill="none" height="11" viewBox="0 0 16 11" width="16" xmlns="http://www.w3.org/2000/svg"> <path d="M0.5 3H2L5 0H11L11.75 0.75L8 4.5L6 2.5L4.5 4L8 7.5L13.25 2.25L14 3H15.5C15.7761 3 16 3.22386 16 3.5C16 3.77614 15.7761 4 15.5 4H15V10C15 10.5523 14.5523 11 14 11H13C12.4477 11 12 10.5523 12 10V9H4V10C4 10.5523 3.55228 11 3 11H2C1.44772 11 1 10.5523 1 10V4H0.5C0.223858 4 0 3.77614 0 3.5C0 3.22386 0.223858 3 0.5 3Z" fill="white"></path> </svg>
                    <span>  {{info.vin}}  </span>
                </span>
            </div>
        </div>
        <EvaluationForm :id="id" v-if="report"/>
    </div>
</template>
<script >
	import {mapGetters} from 'vuex'

	export default {
    name: 'Advertisement',
    props: ['id'],
    components: {
      EvaluationForm: require('./EvaluationForm.vue').default
    },
    computed: {
      ...mapGetters({
        _request: 'estimator/request',
        _report: 'estimator/report',
        _info: 'estimator/info'
      }),
      request() {
        return this._request(this.id);
      },
      report() {
      	return this._report(this.id);
      },
      info() {
        return this._info(this.id);
      },
      title() {
        return `${this.info.brand.name} ${this.info.model.name} ${this.info.year}`
      },
      imgLink() {
        return this.info && this.info.photo ?
							 `https://cdn.riastatic.com/photosnew/auto/photo/___${this.info.photo.id}f.jpg` :
							 'https://img6.auto.ria.com/images/nophoto/no-photo-620x495.jpg'
          ;
      },
      link() {
        return `/auto_${this.info.brand.eng}_${this.info.model.eng}_${this.id}.html`;
      },
      createdAt(){
        const {info: {add: {timestamp} = {}} = {}} = {} = this;
        return timestamp;
      },
      __addDateDiff() {
        if(this.createdAt) {
          const nowM = this.m();
          const addM = this.m(this.createdAt);
          return (Math.round(nowM.diff(addM, 'days', true)) < 2);
        }
        return false;
      },
      __addDateText() {
        const nowM = this.m();
        const addM = this.m(this.createdAt);

        switch (true) {
          case (nowM.diff(addM, 'hours') <= 12):
            return addM.fromNow();
          case (Math.round(nowM.diff(addM, 'days', true)) < 2):
            return addM.calendar(null,
              { sameDay: `[${this.$t('Сегодня')}] HH:mm`, lastDay: `[${this.$t('Вчера')}] HH:mm` });
          default: {
            return addM.format('D.MM.YYYY');
          }
        }
      }
    },
  }
</script>
<style scoped>
    .label-vin:before {
        background: #256799;
        font-size: 8px;
        line-height: 19px;
        color: #fff;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 21px;
        text-align: center;
    }
    .label-vin, .vin-code {
        background: #fff;
        border: 1px solid #256799;
        font-size: 13px;
        line-height: 19px;
        display: inline-block;
        position: relative;
        padding: 0 10px;
        border-radius: 2px;
        margin-right: 10px;
    }
    .label-vin{
        padding-left: 30px;
        cursor: default;
    }
    .label-vin svg {
        position: absolute;
        top: 4px;
        left: 2px;
    }
</style>
