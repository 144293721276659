<template>
    <section class="feed m-padding" :class="{spin_item: !advertisementsIds.length}">
        <template v-if="advertisementsIds.length">
            <Advertisement v-for="id in advertisementsIds" :key="id" :id="id"/>
        </template>
    </section>
</template>
<script>
    import {mapGetters} from 'vuex';
    export default {
      name:'Advertisements',
      components: {
        Advertisement: require('./Advertisement/index.vue').default
      },
      computed: {
        ...mapGetters({
          requests: 'estimator/requests'
        }),
        advertisementsIds(){
          if(this.requests){
            return Object
              .keys(this.requests);
          }
          return [];
        }
      }
    }
</script>
