<template>
    <main class="app-content">
        <vue-fw data-tag="svg" aria-hidden="true" style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <vue-fw data-tag="symbol" id="svg_mileage" width="100%" height="100%" viewBox="0 0 16 16">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14ZM7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 8C7.77614 8 8 7.77614 8 7.5C8 7.22386 7.77614 7 7.5 7C7.22386 7 7 7.22386 7 7.5C7 7.77614 7.22386 8 7.5 8ZM7.5 9C8.32843 9 9 8.32843 9 7.5C9 6.67157 8.32843 6 7.5 6C6.67157 6 6 6.67157 6 7.5C6 8.32843 6.67157 9 7.5 9Z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8536 4.85359L8.85359 6.85359L8.14648 6.14648L10.1465 4.14648L10.8536 4.85359Z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2V0.5H8V2H7ZM3.14645 3.85355L2.14645 2.85355L2.85355 2.14645L3.85355 3.14645L3.14645 3.85355ZM11.1464 3.14645L12.1464 2.14645L12.8536 2.85355L11.8536 3.85355L11.1464 3.14645ZM2 8H0.5V7H2V8ZM13 7H14.5V8H13V7Z"/>
            </vue-fw>
            <vue-fw data-tag="symbol" id="svg_location" width="100%" height="100%" viewBox="0 0 16 16">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8ZM8 9C9.65685 9 11 7.65685 11 6C11 4.34315 9.65685 3 8 3C6.34315 3 5 4.34315 5 6C5 7.65685 6.34315 9 8 9Z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2191 12.2503C11.6896 10.4123 13 8.14202 13 6C13 3.23858 10.7614 1 8 1C5.23858 1 3 3.23858 3 6C3 8.14202 4.31044 10.4123 5.78087 12.2503C6.49841 13.1472 7.21841 13.8975 7.75958 14.4236C7.84452 14.5062 7.9249 14.5831 8 14.6541C8.0751 14.5831 8.15548 14.5062 8.24042 14.4236C8.78159 13.8975 9.50159 13.1472 10.2191 12.2503ZM8 16C8 16 14 11 14 6C14 2.68629 11.3137 0 8 0C4.68629 0 2 2.68629 2 6C2 11 8 16 8 16Z"/>
            </vue-fw>
            <vue-fw data-tag="symbol" id="svg_fuel" width="100%" height="100%" viewBox="0 0 16 16">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.14645 5.14645C4.34171 4.95118 4.65829 4.95118 4.85355 5.14645L5.70711 6H9.29289L10.1464 5.14645C10.3417 4.95118 10.6583 4.95118 10.8536 5.14645C11.0488 5.34171 11.0488 5.65829 10.8536 5.85355L10 6.70711V10.2929L10.8536 11.1464C11.0488 11.3417 11.0488 11.6583 10.8536 11.8536C10.6583 12.0488 10.3417 12.0488 10.1464 11.8536L9.29289 11H5.70711L4.85355 11.8536C4.65829 12.0488 4.34171 12.0488 4.14645 11.8536C3.95118 11.6583 3.95118 11.3417 4.14645 11.1464L5 10.2929V6.70711L4.14645 5.85355C3.95118 5.65829 3.95118 5.34171 4.14645 5.14645ZM6 7V10H9V7H6Z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0H8C6.89543 0 6 0.895431 6 2H5.53553C4.73988 2 3.97682 2.31607 3.41421 2.87868L1.87868 4.41421C1.31607 4.97682 1 5.73988 1 6.53553V13C1 14.1046 1.89543 15 3 15H12C13.1046 15 14 14.1046 14 13V2C14 0.895431 13.1046 0 12 0ZM7 2H10C10.5523 2 11 1.55228 11 1H8C7.44772 1 7 1.44772 7 2ZM12 1C12.5523 1 13 1.44772 13 2V13C13 13.5523 12.5523 14 12 14H3C2.44772 14 2 13.5523 2 13V6.53553C2 6.0051 2.21071 5.49639 2.58579 5.12132L4.12132 3.58579C4.49639 3.21071 5.0051 3 5.53553 3H10C11.1046 3 12 2.10457 12 1Z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.85355 1.14645C3.04882 1.34171 3.04882 1.65829 2.85355 1.85355L0.853553 3.85355C0.658291 4.04882 0.341709 4.04882 0.146447 3.85355C-0.0488155 3.65829 -0.0488155 3.34171 0.146447 3.14645L2.14645 1.14645C2.34171 0.951184 2.65829 0.951184 2.85355 1.14645Z"/>
            </vue-fw>
            <vue-fw data-tag="symbol" id="svg_transmission" width="100%" height="100%" viewBox="0 0 16 16">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14ZM7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 4H5V7H7V4H8V7H10V4H11V8H8V11H7V8H5V11H4V4Z"/>
            </vue-fw>
            <vue-fw data-tag="symbol" id="svg_time" width="100%" height="100%" viewBox="0 0 16 16">
                <path d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,15c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7 S11.9,15,8,15z"/><path d="M8,5.5C8,5.2,7.8,5,7.5,5S7,5.2,7,5.5V9h4.5C11.8,9,12,8.8,12,8.5S11.8,8,11.5,8H8V5.5z"/>
            </vue-fw>
        </vue-fw>
        <h2 class="m-padding" >Отметьте, пожалуйста, параметры для каждого из авто</h2 >

        <Advertisements v-if="isUserAllowed && !empty && !error"/>
        <div class="notice notice--icon notice--red" v-else-if="error">
            <div class="notice_head">
                Произошла ошибка, пожалуйста перезагрузите страницу!
            </div>
        </div>
        <div class="notice notice--icon notice--orange" v-else>
            <div class="notice_head" v-if="!isUserAllowed">
                Страница недоступна
            </div>
            <div class="notice_head" v-else-if="empty">
                Нет новых
            </div>
        </div>
      <Pagination
          @onChangePage="onChangePage"
          :page="page"
          :pages="pages"
          :count="count"/>
    </main>
</template>
<style src="./index.sass" lang="sass" />
<script >
	import {mapActions, mapGetters, mapMutations} from 'vuex';

  const storeModule = require('../../store/estimator');

	export default {
    init({store, route, context}) {
      store.registerModule('estimator', storeModule);
      const page = route.to.query && Number(route.to.query.page) ? Number(route.to.query.page) : 1;
      store.dispatch('estimator/setEstimatorId', route.to.meta.estimatorId);
      store.dispatch('estimator/setPage', page);

      return store
          .dispatch('estimator/count')
          .then(() => store.dispatch('estimator/requestsWithPage'))

    },
    components: {
      Advertisements: require('./Advertisements/index.vue').default,
      Pagination: require('./Pagination.vue').default
    },
    data() {
      return {
        description: 'Перевірені авто на AUTO.RIA для безпечної покупки і швидкого продажу авто"',
        title: 'Перевірені авто на AUTO.RIA ',
        isUserAllowed: true,
        empty: false,
        error: false
      };
    },
    methods: {
      ...mapActions({
        getRequests: 'estimator/requestsWithPage',
        setPage: 'estimator/setPage'
      }),
      ...mapMutations({
        setEstimatorId: 'estimator/setEstimatorId'
      }),
      onChangePage(page) {
        this.$router.push({query: {page}});
        this.setPage(page);
        this.getRequests();
      }
    },
    computed: {
      ...mapGetters({
        requests: 'estimator/requests',
        count: 'estimator/count',
        page: 'estimator/page',
      }),
      withoutAdditionalOptions() {
        return this.$route.meta.withoutAdditionalOptions;
      },
      pages(){
        return Math.ceil(this.count / 20);
      },
      estimatorId() {
      	return this.$route.meta.estimatorId;
      }
    },
    metaInfo() {
      return {
        title: "Оценка авто",
        meta: [
          {name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
        ],
      };
    }
  };

</script>
