import Vue from 'vue';

const reportPlaceholder = {
  id: 0,
  isReseller: false,
  damaged: false,
  realPrice: null,
  noDemand: false,
  isAmerican: false,
  buyPrice: null,
};

const la = require('../_laxios');
module.exports = {
  namespaced: true,
  state: Object.assign({
    count: 0,
    page: 1,
    requests: {},
    autos: {},
    reports: {},
    estimatorId: null,
    reportsLoaded: false
  }, __INITIAL_STATE__.estimator),
  actions: {
    setEstimatorId({commit}, estimatorId) {
      commit('setEstimatorId', estimatorId);
    },
    setPage({ commit }, page) {
      commit('page', page);
    },
    count({commit, getters: {estimatorId, count}}) {
      if (!count) {
        return la
            .estimatorItemCount
            .request({params: {estimatorId}})
            .then(payload => {
              commit('count', payload.count);
              return payload.count;
            })
            .catch(error => {
              console.error('estimator count error');
              return 0;
            })
      }
      return Promise.resolve(count);
    },
    requestsWithPage({commit, getters: {estimatorId, page}}){
      commit('reportsLoaded', false);
      commit('requests', []);
      commit('autos', {});
      commit('reports', {});

      return la
          .estimatorRequestsWithPage
          .request({params: {estimatorId, page}})
          .then(({ids, autos}) => {
            commit('requests', ids);
            commit('autos', autos);
            return ids;
          })
          .then(ids => {
            let requestIds = ids.map(({id}) => Number(id));
            return la
                .estimatorReports
                .request({data: {requestIds, estimatorId}})
                .then(payload => {
                  commit('reportsLoaded', true);
                  commit('reports', payload);
                })
                .then(() => ids)
                ;
          });
    },
    saveField({commit, getters: {request, report, estimatorId}}, {id, data: {key, val}}) {
      const requestId = request(id).id;
      const oldVal = report(id)[key];

      commit('field', {id, data: {key, val}});
      return la
        .saveEstimatorField
        .request({data: {id: requestId, autoId: id, [key]: val, estimatorId}})
        .catch(error => {
          commit('field', {id, data: {key, val: oldVal}});
        })
      ;
    }
  },
  mutations: {
    page(store, page) {
      Vue.set(store, 'page', page);
    },
    count(store, count) {
      Vue.set(store, 'count', count);
    },
    field({reports} = {}, {id, data: {key, val} = {}} = {}) {
      Vue.set(reports[id], key, val);
    },
    requests(store, _payload) {
      let payload = _payload.reduce((acc, curr) => {
        acc[curr.autoId] = curr;
        return acc;
      }, {});
      Vue.set(store, 'requests', payload);
    },
    setEstimatorId(store, id) {
      Vue.set(store, 'estimatorId', id);
    },
    autos(store, _payload) {
      Vue.set(store, 'autos', _payload);
    },
    reports(store, _payload) {
      const payload = Object
          .keys(store.autos)
          .reduce((acc, id) => {
            acc[id] = Object.assign({}, reportPlaceholder, _payload[id]);
            return acc;
            }, {});
      Vue.set(store, 'reports', payload);
    },
    reportsLoaded(store, _payload) {
      Vue.set(store, 'reportsLoaded', _payload);
    },
    deleteField(store, {id, key}) {
      Vue.delete(store.reports[id], key);
    }
  },
  getters: {
    page({ page }) {
      return page;
    },
    count({ count }) {
      return count;
    },
    estimatorId({estimatorId}) {
      return estimatorId;
    },
    reportsLoaded({reportsLoaded}) {
      return reportsLoaded;
    },
    requests({requests = {}} = {}) {
      return requests;
    },
    request({requests = {}} = {}) {
      return autoId => requests[autoId];
    },
    report({reports} = {}) {
      return autoId => reports[autoId];
    },
    reports({reports} = {}) {
      return reports;
    },
    info({autos}) {
      return id => autos[id];
    }
  }
};
